/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				var swiper = new Swiper (
					'.swiper-container',
					{
						speed: 400,
						spaceBetween: 100,
						loop: true,
						autoplay: 8000,
						autoplayDisableOnInteraction: true,
						prevButton: '.swiper-button-prev',
						nextButton: '.swiper-button-next',
						onInit: function(swiper){
							if(swiper.slides.length < 2) {
					        $('.swiper-button-next').hide();
					        $('.swiper-button-prev').hide();
					      }
						}
					}
				);

				$( ".swiper-container" ).hover(
				  function() {
				    swiper.stopAutoplay();
				  }, function() {
				    swiper.startAutoplay();
				  }
				);

				// Variables
				var stickyNavElement = $('#secondary-nav-wrapper');
				var mainOffsetTop = $('main').offset().top;
		        var mainHeader = $('header.banner');
		        var mainHeaderHeight = mainHeader.outerHeight();
				if(stickyNavElement.length){
					var stickyClass = "sticky";
		            var breadcrumb = $('.breadcrumb');
		            var breadcrumbHeight = breadcrumb.outerHeight();
		           	var stickyNavElementOffsetTop = stickyNavElement.offset().top;

			        $(window).scroll(function() {

			        });					
				}


		      	// Custom-made scroll spy
		        // Cache selectors
		        var lastId,
		            topMenu = $(".second-menu "),
		            topMenuHeight = topMenu.outerHeight(),
		            // All list items
		            menuItems = topMenu.find("a"),
		            // Anchors corresponding to menu items
		            scrollItems = menuItems.map(function(){
		              var item = $($(this).attr("href"));
		              if (item.length) { return item; }
		            });


		        // Bind to scroll
		        $(window).scroll(function(){
		           // Get container scroll position
		           mainHeaderHeight = mainHeader.outerHeight();
		           windowTop = $(this).scrollTop();
		           var fromTop = windowTop+topMenuHeight-mainHeaderHeight+180;

		           if(windowTop > 50){
		           		$('body').addClass('small-header');
		           }else{
		           		$('body').removeClass('small-header');
		           }

		           	if (stickyNavElement.length) {
			            if( $(this).scrollTop() > (stickyNavElementOffsetTop-mainHeaderHeight) + (breadcrumb.length ? breadcrumbHeight : 0) ) {
			              stickyNavElement.addClass(stickyClass);
			            } else {
			              stickyNavElement.removeClass(stickyClass);
			        	}
			        }

		           // Get id of current scroll item
		           var cur = scrollItems.map(function(){
		             if ($(this).offset().top < fromTop){
		               return this;
		             }
		           });
		           // Get the id of the current element
		           cur = cur[cur.length-1];
		           var id = cur && cur.length ? cur[0].id : "";
		           
		           if (lastId !== id) {
		               lastId = id;
		               // Set/remove active class
		               menuItems
		                 .parent().removeClass("active")
		                 .end().filter("[href=#"+id+"]").parent().addClass("active");
		           }                   
		        });

		        // Smooth scrolling for secondary nav links
		        stickyNavElement.find('a').click(function(e){
		            $('html, body').animate({
		                scrollTop: $( $.attr(this, 'href') ).offset().top - 50
		            }, 500, function () {
		               //window.location.hash = $(e.currentTarget).attr('href');
		            });
		            return false;
		        });

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired


		  //       $('.dropdown-toggle').bootstrapDropdownHover({
				// });
				$(".dropdown-toggle").click(function(event) {
					if (Modernizr.mq('(max-width: 767px)')) {
						$(this).removeClass('disabled');
					}
				});
				$(".dropdown").hover(
		            function() {
		            	if (Modernizr.mq('(min-width: 768px)')) {
			                $('.dropdown-menu', this).stop( true, true ).show();
			                $(this).toggleClass('open');
			                $('b', this).toggleClass("caret caret-up"); 
						}                
		            },
		            function() {
		            	if (Modernizr.mq('(min-width: 768px)')) {
			                $('.dropdown-menu', this).stop( true, true ).hide();
			                $(this).toggleClass('open');
			                $('b', this).toggleClass("caret caret-up");  					    
						}     
		        });

				// searchform background effect and toggling
				$('#search-form-container').on('show.bs.collapse', function () {
					$('main[role="main"]').addClass('search-active');
					$(this).find('input[type=search]').focus();
				});

				$('#search-form-container').on('hide.bs.collapse', function () {
					$('main[role="main"]').removeClass('search-active');
				});

				$('.search-backdrop').on('click', function(event) {
					$('#search-form-container').collapse('hide');
				});
			}
		},
		// Home page
		'home': {
			init: function () {
				new Swiper (
					'.swiper-container',
					{
						speed: 400,
						spaceBetween: 100,
						prevButton: '.swiper-button-prev',
						nextButton: '.swiper-button-next',
						loop: true,
					}
				);

			},
			finalize: function () {

			}
		},
		// About us page, note the change from about-us to about_us.
		'about': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		},
		// Blog page.
		'blog': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname] (args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire ('common');

			// Fire page-specific init JS, and then finalize JS
			$.each (document.body.className.replace (/-/g, '_').split (/\s+/), function (i, classnm) {
				UTIL.fire (classnm);
				UTIL.fire (classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire ('common', 'finalize');
		}
	};

	// Load Events
	$ (document).ready (UTIL.loadEvents);

}) (jQuery); // Fully reference jQuery after this point.
